import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BookkeepingInvoicePreview, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';
import { state } from './state';

@autoinject
export class BillingCombinedBilling {
  private readonly state = state;
  private previewData: BookkeepingInvoicePreview[] = [];
  private clientId = 0;
  private combinedBillingPreviewList: number[] = [];
  private invoiceDate?: Date;

  constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly notify: Notify, private readonly i18n: I18N) {
  }

  async activate(params: { clientId: string }) {
    this.clientId = parseInt(params.clientId);
    // Keep view-based copy of combinable data
    this.combinedBillingPreviewList = [...this.state.combinedBillingPreviewList];
    await this.refresh();
  }

  attached() {
    this.previewData.forEach(pd => {
      if (!pd.customer) {
        return;
      }
      this.toggleInfo(pd.customer.id);
    });
  }

  async refresh() {
    this.previewData = await this.api.combinedBillingPreview({
      clientId: this.clientId,
      clientBillingId: this.combinedBillingPreviewList,
    });
    if (!this.previewData.length) {
      this.notify.info("client-billing.noCombinableData")
      this.router.navigateToRoute("billing/combined-incomplete-list");
    }
  }

  toggleInfo(id: number) {
    let el = document.querySelector("#data-" + id);
    if (!el || !(el instanceof HTMLDivElement)) {
      return;
    }
    let currentDisplay = el.style.display;
    let newDisplay = "block";
    if (["", "block"].includes(currentDisplay)) {
      newDisplay = "none";
    }
    el.style.display = newDisplay;
  }

  async transferToSalesInvoice(clientBillingId: number) {
    if (!confirm(this.i18n.tr("client-billing.reallyTransfer"))) {
      return;
    }
    let cbAndMeta = await this.api.clientSaleBillingBillingAndMetaById({ id: clientBillingId });
    await this.api.combinedBillingTransferToSalesInvoice({ ...cbAndMeta.cb });
    this.combinedBillingPreviewList = this.combinedBillingPreviewList.filter(x => x !== clientBillingId);
    this.notify.info("client-billing.transferredToSalesInvoice", { id: clientBillingId });
    await this.refresh();
  }

  async deleteCustomerFromList(customerId: number) {
    if (!confirm(this.i18n.tr("client-billing.reallyDeleteCustomer"))) {
      return;
    }
    let customer = this.previewData.find(x => x.customer?.id === customerId)?.customer;
    this.combinedBillingPreviewList = this.previewData.filter(x => x.customer?.id !== customerId).flatMap(x => x.cbList.flatMap(c => c.id));
    if (customer) {
      this.notify.info("client-billing.customerDeletedFromList", { customer: customer.name || customer.officialName });
    }
    await this.refresh();
  }

  async deleteComponentInvoiceFromList(clientBillingId: number) {
    if (!confirm(this.i18n.tr("client-billing.reallyDeleteInvoice"))) {
      return;
    }

    this.combinedBillingPreviewList = this.combinedBillingPreviewList.filter(x => x !== clientBillingId);
    this.notify.info("client-billing.invoiceDeletedFromList", { invoice: clientBillingId });
    await this.refresh();
  }

  edit(id: string) {
    this.router.navigateToRoute('billing/handle', { id });
  }

  async makeSelected() {
    let res = await this.api.combinedBillingInvoiceBills({
      clientId: this.clientId,
      clientBillingId: this.combinedBillingPreviewList,
      invoiceDate: this.invoiceDate,
    });
    if (res && res.count === 0) {
      this.notify.info("client-billing.combinedBillingNotFormed");
      return;
    }
    this.notify.info("client-billing.combinedBillingFormed");
    this.router.navigateToRoute("billing/combined-list");
  }
}
